import Profile from "frontend/pages/Profile/Index";
import RequireAuth from "frontend/components/Auth";
import { Routes, Route } from "react-router-dom";
import Home from "frontend/pages/Home/Index";
import Playground from "frontend/pages/Playground/Index";
import Authentication from "frontend/pages/Authentication/Index";
import ProfileComponentsEnum from "frontend/ComponentsEnum";
import PrivacyPolicy from "frontend/pages/PrivacyPolicy/Index";
import CookiesManagement from "frontend/pages/CookiesManagement/Index";

/**
 * Component that maps routes to their corresponding pages. Also protects them via Authentication.
 * @returns Component.
 */
const AppRoutes = () => {
    return (
        <Routes>
            <Route
                path="/profile/overview"
                element={
                    <RequireAuth>
                        <Profile activeComponentProp={ProfileComponentsEnum.OVERVIEW} />
                    </RequireAuth>
                }
            />
            <Route
                path="/profile/settings"
                element={
                    <RequireAuth>
                        <Profile
                            activeComponentProp={ProfileComponentsEnum.ACCOUNT_SETTINGS}
                            isEditMode={false}
                        />
                    </RequireAuth>
                }
            />
            <Route
                path="/profile/billing"
                element={
                    <RequireAuth>
                        <Profile
                            activeComponentProp={ProfileComponentsEnum.BILLING_INFO}
                            isEditMode={false}
                        />
                    </RequireAuth>
                }
            />
            <Route
                path="/profile/settings/edit"
                element={
                    <RequireAuth>
                        <Profile
                            activeComponentProp={ProfileComponentsEnum.ACCOUNT_SETTINGS}
                            isEditMode={true}
                        />
                    </RequireAuth>
                }
            />
            <Route
                path="/profile/billing/edit"
                element={
                    <RequireAuth>
                        <Profile
                            activeComponentProp={ProfileComponentsEnum.BILLING_INFO}
                            isEditMode={true}
                        />
                    </RequireAuth>
                }
            />
            <Route
                path="/profile/playground"
                element={
                    <RequireAuth>
                        <Profile activeComponentProp={ProfileComponentsEnum.PLAYGROUND} />
                    </RequireAuth>
                }
            />
            <Route path="/register" element={<Authentication isRegisterProp={true} />} />
            <Route path="/login" element={<Authentication isRegisterProp={false} />} />
            <Route path="/" element={<Home />} />
            <Route path="/playground" element={<Playground isBackground={true} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookies-management" element={<CookiesManagement />} />
        </Routes>
    );
};

export default AppRoutes;
